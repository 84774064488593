@import '1-tools/-tools-dir'

// $projects: 

//------------------------------------------------
// Set some colors as sass variables

$accentLight: #d2c7ff
$accentDark: #6141ac

$textColor: #666
$headlineColor: #333
$bgcolor: #e5eaee
$documentBackgroundColor: #fff

@import '2-base/-base-dir'
@import '3-sections/-sections-dir'
