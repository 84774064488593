.two-column
  max-width: 70%
  margin: auto
  display: flex
  justify-content: space-between
  padding: 20px
  .column-left
    flex: 2
    margin-right: 20px
    img
      width: 100%
      height: auto
  .column-right
    flex: 3

@media (max-width: 768px)
  .two-column
    flex-direction: column
    align-items: center

  .column-left
    margin-right: 0
    margin-bottom: 20px

@media (min-width: 2000px)
  .two-column
    font:
      size: 1.5em
