.blog-container
  width: 80%
  padding-top: 10px
  margin: auto
  .blog-unit
    margin: 30px auto
    width: 800px
    h1
      display: inline-block
      margin: 10px 0px 0px
      padding: 0px
      font:
        size: 24px
        weight: 700
      a
        color: black    
      &:hover a
        text-decoration: none
        color: $accentDark
    .blog-date
      display: block
      color: grey
      font:
        size: 12px
        weight: 700
    img
      width: 100%
      max-height: 305px
      object-fit: cover
    hr
      border: 1px solid $accentLight  
    a
      color: $accentDark
@media (max-width: 768px)
  .blog-container 
    .blog-unit 
      // this feels a bit hacky but I don't understand CSS!
      width: auto
      img
        max-width: 100%
