/*
	Animation: topDown
	Source: Dylan Spicker (but really, Travis Nielson) */

// Initial Conditions
%animationstart_topDown
	+size(100%)
	+position(absolute, -100% 0px null 0px)
	+transition

%topDown
	top: 0%