.thumb-container
  max-width: 1200px
  margin: 0px auto 0px
  padding: 30px
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  +clearfix
  .thumb-unit
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
    cursor: pointer
    display: block
    width: 300px
    height: 300px
    margin: 0px auto 30px
    background:
      position: center center
      repeat: no-repeat
      size: cover
    display: flex
    align-items: flex-end
    .thumb-overlay
      padding: 10px
      width: 100%
      background: fade-out(#111111, 0.2)
      color: white
      letter-spacing: 0.1em
      font-family: "Roboto Slab"
      font-size: 18px
      .project-title
        margin-bottom: 5px
      .project-subtitle
        font-size: 14px
        font-style: italic
    &:hover .thumb-overlay
      font-weight: bold
      background: fade-out(#111111, 0.1)

  &:hover a
    text-decoration: none


@media (max-width: 768px)
  .thumb-container
    max-width: 80vw
    grid-template-columns: repeat(auto-fill, minmax(65vw, 1fr))
    .thumb-unit
      width: 65vw
      height: 65vw
      .thumb-overlay
        .project-title
          font-size: 16px
          margin-bottom: 4px
        .project-subtitle
          font-size: 12px


@media (max-width: 400px)
  .thumb-container
    grid-template-columns: repeat(auto-fill, minmax(60vw, 1fr))
    .thumb-unit
      width: 60vw
      height: 65vw
      .thumb-overlay
        .project-title
          font-size: 14px
          margin-bottom: 3px
        .project-subtitle
          font-size: 10px
