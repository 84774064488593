nav
	background-color: $accentLight	
	text-align: center
	margin: 30px 0 0 0
	padding: 15px
	a
		margin-left: 20px
		margin-right: 20px
		font-family: sans-serif
		color: rgba(0,0,0,1)
		font:
			size: 16px
			weight: 200
		&:hover
			color: inherit
			text-decoration: none
	.active
		font-weight: 600

@media (max-width: 768px)
	nav
		margin: 0px
		margin-top: 18px
		a
			margin-left: 0px
			margin-right: 10px
			font:
				size: 12px