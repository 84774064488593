/*
	Animation: topDoor
	Source: Dylan Spicker (but really, Travis Nielson) */

// Initial Conditions
%animationstart_topDoor
	+size(100%)
	+position(absolute, -50% 0px null 0px)
	+transition(1s)
	+transform(rotateX(90deg))
	+transform-origin(center top)

%topDoor
	top: 0%
	+transform(rotateX(0deg))