/* 
	Animation: fadeIn
	Source: Dylan Spicker 
	Rewrite: niklasravnsborg */

// Initial Class
%animationstart_fadeIn
	+position(0,0)
	+transition(800ms)
	opacity: 0

// Animation Class
%fadeIn
	opacity: 1