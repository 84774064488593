.image-container
  position: relative
  width: 100%
  margin-bottom: 20px
  &:hover .overlay
    opacity: 1
  .image
    display: block
    width: 100%
    height: auto
  .overlay
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    opacity: 0
    //transition: .5s ease
    background-color: rgba(255,255,255,0.05)
  .text
    color: black
    font:
      size: 24px
      weight: 700
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    -ms-transform: translate(-50%, -50%)
