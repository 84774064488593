.page-content
  margin: 30px auto
  width: 80%
  max-width: 800px
  h1
    line-height: 1.5
    color: black
    font:
      size: 30px
      weight: 700
  h3
    padding-top: 0px
  p
    line-height: 1.5
    font:
      size: 16px
  iframe
    width: 720px
    height: 405px
  a
    color: $accentDark

@media (max-width: 768px)
  .page-content
    width: 80vw
    // todo: this is a hack because I can't get this working: 
    // https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
    iframe
      width: 80vw
      height: 45vw
