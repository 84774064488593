/* 
	Animation: bottomUp
	Source: Travis Nielson */

// Initial Class
%animationstart_bottomUp
	+size(100%)
	+position(absolute, 100% 0px null 0px)
	+transition

// Animation Class
%bottomUp
	opacity: 1
	top: 0%