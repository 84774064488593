.work-container
  max-width: 800px
  margin: 0px auto 30px
  position: relative
  display: block
  h1, h2, h3, h4
    font-family: "Roboto Slab"
    a
      color: #333
  h4
    color: black
    line-height: 1.2
    margin-bottom: 10px
    font:
      weight: 700
      size: 24px
  img
    max-width: 100%
  a
    color: $accentDark
    &:hover img
      opacity: 0.5
  .work-return
    cursor: pointer
    font:
      size: 24px
    +position(absolute, -3px null null -35px)
  .loading
    text-align: center
  .blog-header
    margin: 10px 0
    width: 100%
  .project-title
    text-transform: uppercase
  .image-source
    text-align: center
    font:
      style: italic
      size: 12px
  .blog-date
    color: grey
    font:
      style: italic
      size: 14px
  .project-load
    width: 90%
    margin: auto
  iframe
    width: 720px
    height: 405px
  .gallery
    display: grid
    grid-template-columns: repeat(6, 1fr)
    grid-template-rows: repeat(20, 5vw)
    grid-gap: 5px
    .gallery-img
      width: 100%
      height: 100%
      object-fit: cover

@media (max-width: 768px)
  .work-container 
    // todo: this is a hack because I can't get this working: 
    // https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
    iframe
      width: 90vw
      height: 50vw
    .gallery
      grid-template-rows: repeat(20, 9vw)
