/*
	Animation: bottomDoor
	Source: Travis Nielson */

// Initial Conditions
%animationstart_bottomDoor
	+size(100%)
	+position(absolute, 50% 0px null 0px)
	+transition(800ms)
	+transform(rotateX(90deg))
	+transform-origin(center bottom)

%bottomDoor
	top: 0%
	+transform(rotateX(0deg))