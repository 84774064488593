footer
  height: 100px
  .footer-wrap
    max-width: 500px
    margin: 0px auto 0px
    padding: 10px
    .footer-tagline
      text-align: center
      color: rgba(0,0,0,1)
      font:
        size: 24px
        weight: 700
    .social-media
      text-align: center
      div
        margin: 0px
        padding: 0px
        a
          margin: 10px
          color: rgba(0,0,0,1)
          font:
            size: 20px
            weight: 700
          &:hover
            color: $accentLight
