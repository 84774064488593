/* 
	Animation: rightLeft
	Source: Dylan Spicker (but like really, no skill needed here) */

// Initial Class
%animationstart_rightLeft
	top: 0%
	left: 100%
	+size(100%)
	+transition(800ms)

// Animation Class
%rightLeft
	left: 0%