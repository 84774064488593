%small-bold
  font:
    size: 14px
    weight: 700
  text-transform: uppercase

%background-image
  background:
    repeat: no-repeat, no-repeat
    size: 50px 50px, cover
    position: 30px 50px, 50% 50%
    blend-mode: overlay, normal
