header
  height: 90px
  text-align: center
  display: inline-block
  margin-bottom: 10px
  .logo
    letter-spacing: 0.1em
    font-family: "Roboto Slab"
    margin: 30px
    font:
      size: 36px
      weight: 400
    a
      color: rgba(0,0,0,1)
      &:hover
        color: $accentLight
    h1
      letter-spacing: 0.1em
      font:
        weight: inherit
        size: inherit
    .subtitle
      font-size: 40%
      display: block
      font-family: sans-serif

@media (max-width: 768px)
  header
    .logo
      font-size: 32px
